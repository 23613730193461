import React from 'react'

import infusionList from '@/constants/infusionList'
import Catalog from '@/components/Catalog'

import generateInfusionPageUrlById from "@/utils/generateInfusionPageUrlById"

import filterListOriginal from './constants/filterList'

const InfusionCatalogContainer = () => (
  <Catalog
    pageUrlGenerator={generateInfusionPageUrlById}
    pageTitle='Каталог инфузий'
    itemNameList={infusionList}
    itemList={infusionList}
    filterListOriginal={filterListOriginal}
  />
)

export default InfusionCatalogContainer
