import arrify from 'arrify'
import React from 'react'

import AbandonedAt from '@/components/AbandonedAt'
import Creature from '@/components/Creature'
import SourceInfo from '@/components/SourceInfo'
import HeaderBlock from '@/components/HeaderBlock'
import Markdown from '@/components/Markdown'
import ReleasedAs from '@/components/ReleasedAs'
import ReleasedBeforeAs from '@/components/ReleasedBeforeAs'

import generateTextLinks from '@/utils/generateTextLinks'
import generateInfusionPageUrlById from '@/utils/generateInfusionPageUrlById'

import { infusionCollection } from '@/constants/infusionList'

import './InfusionStyles.less'

const InfusionComponent = (
  {
    abandonedAt,
    creatureId,
    description,
    header,
    releasedAs,
    releasedBeforeAsList,
    subHeader,
    source,
    requirement,
    type,
  },
) => (
  <section className='Infusion'>
    <section className='Infusion_infoColumn'>
      <section className='Infusion_infoBlock'>
        <HeaderBlock
          className='Infusion_header'
          subHeader={subHeader}
        >
          {header}
        </HeaderBlock>
        {
          requirement
            ? (
              <div className='Infusion_requirement'>
                Требования: {requirement}
              </div>
            )
            : null
        }
        {
          type
            ? (
              <div className='Infusion_requirement'>
                Инфузируемый предмет: {type}
              </div>
            )
            : null
        }
        <AbandonedAt
          className='Infusion_releaseInfo'
          preText='Эта инфузия была отменена'
          abandonedAt={abandonedAt}
        />
        <ReleasedAs
          className='Infusion_releaseInfo'
          preText='Эта инфузия была переиздана'
          releasedAs={releasedAs}
          collection={infusionCollection}
          urlGenerator={generateInfusionPageUrlById}
        />
        <Markdown className='Infusion_description'>
          {generateTextLinks(description)}
        </Markdown>
        <SourceInfo
          className='Infusion_source'
          source={source}
          useFullName
        />
      </section>
      <ReleasedBeforeAs
        preText='Эта инфузия ранее издавалась'
        releasedAs={releasedBeforeAsList}
        collection={infusionCollection}
        urlGenerator={generateInfusionPageUrlById}
        checkIsReady={false}
      />
    </section>
    {
      creatureId
        ? arrify(creatureId).map(
          id => <Creature id={id}/>
        )
        : null
    }
  </section>
)

export default InfusionComponent
