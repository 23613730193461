import React from 'react'

import Infusion from '@/components/Infusion'
import InfusionCatalog from '@/components/InfusionCatalog'

import {infusionCollection} from '@/constants/infusionList'

import CommonCatalogPageTemplate from '@/templates/CommonCatalogPageTemplate'

const defaultSeoData = {
  title: 'Инфузии',
  description: 'Каталог всех инфузий изобретателя в Dungeons & Dragons',
}

const InfusionPageTemplate = (props) => (
  <CommonCatalogPageTemplate
    defaultSeoData={defaultSeoData}
    itemCollection={infusionCollection}
    ItemComponent={Infusion}
    ListComponent={InfusionCatalog}
    {...props}
  />
)

export default InfusionPageTemplate
