import DEFAULT_FILTER_VALUE from '@/constants/DEFAULT_FILTER_VALUE'
import SEARCH_PROP_NAME from '@/constants/SEARCH_PROP_NAME'

export default [
  {
    header: 'Переиздание',
    children: [
      {
        label: 'Отменена?',
        title: 'Инфузия существовала только в Раскопанных Тайнах и позже было решено её не издавать?',
        type: 'select',
        propName: 'isAbandoned',
        value: false,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Неважно',
          },
          {
            value: true,
            text: 'Да',
          },
          {
            value: false,
            text: 'Нет',
          },
        ],
      },
      {
        label: 'Переиздана?',
        title: 'Инфузия была переиздана в другой официальной версии',
        type: 'select',
        propName: 'isReReleased',
        value: false,
        list: [
          {
            value: DEFAULT_FILTER_VALUE,
            text: 'Неважно',
          },
          {
            value: true,
            text: 'Да',
          },
          {
            value: false,
            text: 'Нет',
          },
        ],
      },
    ],
  },
  {
    label: 'Введите название инфузии или её текст',
    type: 'input',
    propName: SEARCH_PROP_NAME,
    value: DEFAULT_FILTER_VALUE,
  },
]
