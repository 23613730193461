import React from 'react'

import { infusionCollection } from '@/constants/infusionList'

import generateNameStr from '@/utils/generateNameStr'

import InfusionComponent from './InfusionComponent'

const InfusionContainer = ({ id }) => {
  if (id) {
    const infusion = infusionCollection[id]

    if (infusion) {
      const {requiredLvl, ...rest} = infusion
      const {header, subHeader} = generateNameStr(infusion)
      const requirement = requiredLvl
        ? `изобретатель ${requiredLvl} уровня`
        : ``

      return (
        <InfusionComponent
          header={header}
          subHeader={subHeader}
          requirement={requirement}
          {...rest}
        />
      )
    }
  }

  return null
}

export default InfusionContainer
